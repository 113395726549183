<!--wordcloud-->
<template>
  <div class="flex h-full overflow-hidden">
    <el-container class="flex flex-1 relative">
      <!-- Left sidebar -->
      <el-aside v-show="showDataPanel" class="w-72 transition-all overflow-y-auto border-r border-gray-300 custom-scrollbar">
        <!-- Left sidebar content -->
        <div class="p-4">
          <p>
            <el-button type="warning" @click="priceVisible = true" class="rounded-full">{{ $t('Price') }}</el-button>
          </p>
          <h2 class="text-lg font-semibold">{{ $t('Data') }}</h2>
          <el-input v-model="textToAnalyze" :placeholder="$t('LongText')" class="w-full mt-2" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" clearable></el-input>
          <el-button type="success" class="mt-2 rounded-full" @click="analyzeText">{{ $t('Analyze') }}</el-button>

          <el-input v-model="key" :placeholder="$t('Enter key')" class="w-full mt-2" clearable></el-input>
          <el-input v-model="value" :placeholder="$t('Enter value')" class="w-full mt-2" clearable></el-input>
          <el-button type="primary" class="mt-2 rounded-full" @click="addData" round>{{ $t('Add Data') }}</el-button>
        </div>
        <hr>
        <div class="p-4">
          <el-scrollbar max-height="50vh" class="custom-scrollbar">
            <el-table :data="chartData" class="w-full">
              <el-table-column prop="name" :label="$t('Name')" />
              <el-table-column prop="value" :label="$t('Value')" />
              <el-table-column :label="$t('Operations')" width="180">
                <template #default="scope">
                  <el-button circle type="danger" @click="deleteData(scope.$index)">
                    <el-icon><Delete /></el-icon>
                  </el-button>                  
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <hr>
        <div class="p-4">
          <el-select v-model="selectedLang" @change="changeLang" placeholder="Select Language" class="w-full">
            <el-option v-for="(item, index) in Object.values($i18n.messages.tag_label)" :key="index" :label="item"
              :value="$i18n.messages.label_tag[item]"></el-option>
          </el-select>
          <p class="mt-4">
            <el-button plain @click="serviceVisible = true" class="rounded-full">{{ $t('Service') }}</el-button>
          </p>
          <p class="mt-4 text-sm text-gray-500">
            Copyright &copy; emperinter
            <br>
            <a href="/sitemap.xml" target="_blank">sitemap</a>
            <br>
            <a href="/blog" target="_blank">Blog</a>
            <br>
            <a href="https://apps.apple.com/us/app/wordcloudstudio-wordart-tool/id6504160406" target="_blank">WordCloudStudio</a>
          </p>
        </div>
      </el-aside>

      <!-- Toggle button for left sidebar -->
      <div class="absolute top-1/2 transform -translate-y-1/2 z-50 bg-white border border-gray-300 p-1 cursor-pointer transition-all rounded-full" :class="{ 'left-0': !showDataPanel, 'left-72': showDataPanel }" @click="toggleDataPanel">
        <el-icon><ArrowLeft v-if="showDataPanel" /><ArrowRight v-else /></el-icon>
      </div>

      <!-- Main content area -->
      <el-main class="flex-1 overflow-hidden">
        <!-- WordCloud -->
        <div class="flex flex-col h-full bg-blue-100 bg-grid overflow-hidden">
          <div class="flex-1 flex justify-center items-center overflow-auto" ref="editorContent">
            <div class="content-wrapper" :style="contentWrapperStyle">
              <div v-if="isSubscribed" ref="chart" class="shadow-md" :style="{ backgroundColor, width: `${imageWidth}px`, height: `${imageHeight}px` }"></div>
              <!-- Carousel for non-subscribed users -->
              <el-carousel v-else :interval="5000" arrow="always" class="shadow-md overflow-hidden" :style="{ width: '400px', height: '325px' }">
                <el-carousel-item v-for="(image, index) in imageUrls" :key="index">
                  <div class="w-full h-full flex items-center justify-center" style="background-color: #ffffff;">
                    <img :src="require(`@/assets/${image}`)" :alt="image" style="max-width: 100%; max-height: 100%; width: auto; height: auto; object-fit: contain;">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="flex justify-center items-center p-2 gap-2 bg-blue-50 border-t border-blue-200">
            <el-button circle @click="zoomOut" class="rounded-full">
              <el-icon><Remove /></el-icon>
            </el-button>
            <el-button circle @click="zoomIn" class="rounded-full">
              <el-icon><Plus /></el-icon>
            </el-button>
            <el-button circle @click="resetZoom" class="rounded-full">
              <el-icon><Refresh /></el-icon>
            </el-button>
            <span class="text-sm text-gray-600">{{ Math.round(zoomLevel * 100) }}%</span>
          </div>
        </div>
      </el-main>


      <!-- Toggle button for right sidebar -->
      <div class="absolute top-1/2 transform -translate-y-1/2 z-50 bg-white border border-gray-300 p-1 cursor-pointer transition-all rounded-full" :class="{ 'right-0': !showOptionsPanel, 'right-72': showOptionsPanel }" @click="toggleOptionsPanel">
        <el-icon><ArrowRight v-if="showOptionsPanel" /><ArrowLeft v-else /></el-icon>
      </div>

      <!-- Right sidebar -->
      <el-aside v-show="showOptionsPanel" class="w-72 transition-all overflow-y-auto border-l border-gray-300 custom-scrollbar">
        <!-- Right sidebar content -->
        <el-scrollbar class="h-full p-4 custom-scrollbar">
          <div>
            <h1 class="text-lg font-semibold">{{ $t('Options') }}</h1>
            <el-button type="success" class="mt-2 rounded-full" @click="exportLayout" round>
              {{ $t('OutPut') }}<el-icon class="el-icon--right">
                <Upload />
              </el-icon>
            </el-button>
            <el-divider></el-divider>

            <h2 class="text-lg font-semibold">{{ $t('Mask') }}</h2>
            <el-button type="warning" class="mt-2 rounded-full" @click="maskVisible = true">{{ $t('Masks') }}</el-button>
            
            <el-divider></el-divider>
            <h2 class="text-lg font-semibold">{{ $t('Image') }}</h2>
            <p>{{ $t('ImageSize') }}</p>
            <el-select v-model="selectedSize" @change="updateImageSize" placeholder="Select Size" class="w-full mt-2">
              <el-option
                v-for="size in defaultSizes"
                :key="size.tag"
                :label="size.tag"
                :value="size"
              ></el-option>
            </el-select>            
            <p class="mt-2">{{ $t('imageWidth') }}</p>
            <el-input-number v-model="imageWidth" :min="10" :max="10000" :label="$t('imageWidth')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('imageHeight') }}</p>
            <el-input-number v-model="imageHeight" :min="10" :max="10000" :label="$t('imageHeight')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('backgroundColor') }}</p>
            
            <el-color-picker v-model="backgroundColor" show-alpha :predefine="predefineColors"
              :label="$t('Background Color')" size="large" class="w-full mt-2"></el-color-picker>
            <el-divider></el-divider>

            <h2 class="text-lg font-semibold">{{ $t('FontSet') }}</h2>
            <p class="mt-2">{{ $t('Font') }}</p>
            <el-select v-model="selectedFont" :placeholder="$t('SelectFont')" class="w-full mt-2">
              <el-option
                v-for="font in fontOptions"
                :key="font.value"
                :label="font.label"
                :value="font.value"
              ></el-option>
            </el-select>            
            <p class="mt-2">{{ $t('minSize') }}</p>
            <el-input-number v-model="minSize" :min="10" :max="10000" :label="$t('minSize')" class="w-full mt-2"></el-input-number>
            <p class="mt-2">{{ $t('maxSize') }}</p>
            <el-input-number v-model="maxSize" :min="10" :max="10000" :label="$t('maxSize')" class="w-full mt-2"></el-input-number>
            <el-divider></el-divider>
          </div>
        </el-scrollbar>
      </el-aside>
    </el-container>

    <el-dialog v-model="maskVisible" :title="$t('Masks')" width="500" :before-close="handleClose">
      <div v-if="this.hasPurchased()">
        <SelectMask @mask-selected="handleMaskSelected" />
      </div>
      <div v-else>
        <el-tabs :tab-position="tabPosition" label-width="250px">
          <el-tab-pane :label="$t('Plan')">
            <template #label>
              <el-icon><PriceTag /></el-icon>
              {{ $t('Plan') }}
            </template>
            <PricePage />
          </el-tab-pane>
          <span></span>
          <el-tab-pane :label="$t('Account')">
            <template #label>
              <el-icon><User /></el-icon>
              {{ $t('Account') }}
            </template>
            <Account />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>


    <el-dialog v-model="priceVisible" :title="$t('')" width="500" :before-close="handleClose">
      <el-tabs :tab-position="tabPosition" label-width="250px">
        <el-tab-pane :label="$t('Plan')">
          <template #label>
            <el-icon><PriceTag /></el-icon>
            {{ $t('Plan') }}
          </template>
          <PricePage />
        </el-tab-pane>
        <span></span>
        <el-tab-pane :label="$t('Account')">
          <template #label>
            <el-icon><User /></el-icon>
            {{ $t('Account') }}
          </template>
          <Account />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog v-model="serviceVisible" :title="$t('Service')" width="500" :before-close="handleClose">
      <Service />
    </el-dialog>
  </div>
</template>

<style scoped>
.bg-grid {
  background-image: linear-gradient(#ffffff 2px, transparent 2px),
    linear-gradient(90deg, #ffffff 2px, transparent 2px);
  background-size: 10px 10px;
}

/* 美化滚动条 */
.custom-scrollbar {
  overflow-y: auto; /* 允许垂直滚动 */
  scrollbar-width: thin; /* Firefox 滚动条为细 */
  scrollbar-color: rgba(136, 136, 136, 0.5) transparent; /* 半透明滚动条颜色和透明轨道颜色 */
}

/* 隐藏滚动条 */
.custom-scrollbar::-webkit-scrollbar {
  width: 0; /* 默认隐藏滚动条 */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  width: 4px; /* 鼠标悬停时显示滚动条 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* 轨道颜色透明 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.5); /* 半透明滚动条颜色 */
  border-radius: 2px; /* 圆角 */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.8); /* 悬停时颜色稍深 */
}
</style>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { ElInput, ElButton, ElInputNumber, ElColorPicker, ElNotification } from 'element-plus';
import { Upload, Remove, Plus, Refresh, Delete, ArrowLeft, ArrowRight, PriceTag, User } from '@element-plus/icons-vue';
import SelectMask from './SelectMask.vue';
import { ref } from 'vue'
import html2canvas from 'html2canvas';
import PricePage from './PricePage.vue';
import Service from './Service.vue';
import Account from './Account.vue';
import dogImg from '../assets/love.png';
import FontFaceObserver from 'fontfaceobserver';

export default {
  name: 'HomePage',
  components: {
    ElInput,
    ElButton,
    ElInputNumber,
    ElColorPicker,
    SelectMask,
    Upload,
    PricePage,
    Service,
    Account,
    Remove,
    Plus,
    Refresh,
    Delete,
    ArrowLeft,
    ArrowRight,
    PriceTag,
    User
  },
  setup() {
    const predefineColors = ref([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsv(51, 100, 98)',
      'hsva(120, 40, 94, 0.5)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
    ]);

    return {
      predefineColors
    }
  },
  data() {
    return {
      chartData: [], // 初始化一批数据
      key: '',
      value: '',
      editingIndex: -1,
      backgroundColor: '#FFFFFF',
      maskVisible: false,
      imageHeight: 512,
      imageWidth: 512,
      priceVisible: false,
      serviceVisible: false,
      selectedLang: null,
      selectedMask: '',
      isSubscribed: false,
      minSize: 5,
      maxSize: 1000,
      selectedFont: '',
      fontOptions: [],
      textToAnalyze: '',
      selectedSize: null,
      imageUrls: [
        'cat.png',
        'flight.png',
        'flower.png',
        'football.png',
        'star.png',
      ],    
      defaultSizes: [
        { width: 800, height: 600, tag: "5 inch" },
        { width: 1024, height: 768, tag: "6 inch" },
        { width: 1600, height: 1200, tag: "10 inch" },
        { width: 2048, height: 1536, tag: "20 inch" },
        { width: 2560, height: 1440, tag: "QHD" },
        { width: 3840, height: 2160, tag: "4K UHD" },
        { width: 7680, height: 4320, tag: "8K UHD" },
        { width: 320, height: 240, tag: "QVGA" },
        { width: 640, height: 480, tag: "VGA" },
        { width: 1280, height: 720, tag: "HD" },
        { width: 1920, height: 1080, tag: "Full HD" },
        { width: 2560, height: 1600, tag: "WQXGA" },
        { width: 3440, height: 1440, tag: "UWQHD" },
        { width: 5120, height: 2880, tag: "5K" },
        { width: 6016, height: 3384, tag: "6K" },
        { width: 1240, height: 1754, tag: "A6" },
        { width: 1748, height: 2480, tag: "A5" },
        { width: 2480, height: 3508, tag: "A4" },
        { width: 3508, height: 4961, tag: "A3" },
        { width: 4961, height: 7016, tag: "A2" },
        { width: 7016, height: 9933, tag: "A1" },
        { width: 9933, height: 14043, tag: "A0" },
        { width: 500, height: 500, tag: "500 x 500" },
        { width: 1000, height: 1000, tag: "1000 x 1000" },
        { width: 1500, height: 1500, tag: "1500 x 1500" },
        { width: 2000, height: 2000, tag: "2000 x 2000" },
        { width: 2500, height: 2500, tag: "2500 x 2500" },
        { width: 3000, height: 3000, tag: "3000 x 3000" }
      ],      
      zoomLevel: 1,
      contentPosition: { x: 0, y: 0 },
      showDataPanel: true,
      showOptionsPanel: true,
      fontsLoaded: false, // 新增标志位
    };
  },
  created() {
    // 监听 imageHeight 变化
    this.$watch('imageHeight', (newValue, oldValue) => {
      console.log('imageHeight 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    // 监听 imageWidth 变化
    this.$watch('imageWidth', (newValue, oldValue) => {
      console.log('imageWidth 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('minSize', (newValue, oldValue) => {
      console.log('minSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('maxSize', (newValue, oldValue) => {
      console.log('maxSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('chartData', (newValue, oldValue) => {
      console.log('chartData 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });    
    this.$watch('selectedFont', (newValue, oldValue) => {
      console.log('selectedFont 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('selectedSize', (newValue, oldValue) => {
      console.log('selectedSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });        
  },
  computed: {
    isInputValid() {    
      console.log("isInputValid",this.key.trim() !== '' && this.value.trim() !== '');
      return this.key.trim() !== '' && this.value.trim() !== '';
    },
    editorContentStyle() {
      return {
        transform: `scale(${this.zoomLevel})`,
        transformOrigin: 'top left',
      };
    },
    contentWrapperStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.contentPosition.x}px, ${this.contentPosition.y}px)`,
        transformOrigin: 'center',
      };
    },
  },
  mounted() {
    this.selectedLang = this.$i18n.locale;
    this.generateData();
    this.loadFonts();
    this.hasPurchased();
    this.initChart();
  },
  methods: {
    toggleDataPanel() {
      this.showDataPanel = !this.showDataPanel;
    },
    toggleOptionsPanel() {
      this.showOptionsPanel = !this.showOptionsPanel;
    },    
    zoomIn() {
      this.zoomLevel = Math.min(this.zoomLevel * 1.2, 3);
    },
    zoomOut() {
      this.zoomLevel = Math.max(this.zoomLevel / 1.2, 0.1);
    },
    resetZoom() {
      this.zoomLevel = 1;
    },    
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
    updateImageSize(size) {
      this.imageWidth = size.width;
      this.imageHeight = size.height;
    },    
    async loadFonts() {
      // 初始化字体选项
      this.fontOptions = [
        { label: 'Arial', value: 'Arial' },
        { label: 'Times New Roman', value: 'Times New Roman' },
        { label: 'Courier New', value: 'Courier New' },
        { label: 'Georgia', value: 'Georgia' },
        { label: 'Verdana', value: 'Verdana' },
        // 添加更多常用字体选项
      ];
      
      // 设置默认字体
      if (!this.selectedFont) {
        this.selectedFont = 'Arial';
      }
      
      // 加载默认字体
      await this.loadSelectedFont(this.selectedFont);
    },

    async loadSelectedFont(fontFamily) {
      try {
        const font = new FontFaceObserver(fontFamily);
        await font.load();
        console.log(`Font ${fontFamily} loaded successfully`);
      } catch (error) {
        console.error(`Error loading font ${fontFamily}:`, error);
        // 如果加载失败，使用后备字体
        this.selectedFont = 'Arial';
      }
    },
    initChart() {
      const chart = echarts.init(this.$refs.chart);
      var option = {
        sizeRange: [this.minSize, this.maxSize],
        rotationRange: [-90, 90],
        rotationStep: 1,
        gridSize: 1,
        drawOutOfBound: false,
        shrinkToFit: true,
        keepAspect: false,
        layoutAnimation: true,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        right: 0,
        bottom: 0,
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          textStyle: {
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            color: function () {
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')';
            }
          },
          data: this.chartData
        }]
      };

      var maskImage = new Image();
      maskImage.onload = function () {
        option.series[0].maskImage = maskImage;
        chart.setOption(option);
      };
      // maskImage.src = "https://wordcloudmaster-preimages.emperinter.com/dog";
      maskImage.src = dogImg;
    },
    Notify() {
      ElNotification({
        title: this.$t("Warning"),
        message: this.$t("InvalidInput"),
        position: 'top-left',
        type: 'warning'
      })
    },
    analyzeText() {
      if (this.hasPurchased()) {
        const words = this.textToAnalyze.split(/\s+/); // 按空格分割文本
        const wordCount = {};

        words.forEach(word => {
          if (word) {
            wordCount[word] = (wordCount[word] || 0) + 1; // 统计词频
          }
        });

        // 将结果添加到 chartData
        this.chartData = Object.entries(wordCount).map(([name, value]) => ({ name, value }));
        this.updateChart(); // 更新图表
        this.textToAnalyze = ''; // 清空输入框
      }else{
        this.priceVisible = true;
      }
    },
    hasPurchased() {
      const purchaseStatus = localStorage.getItem("purchaseStatus");
      const expirationTime = localStorage.getItem("expirationTime");
      const hasPurchased = purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime);
      return hasPurchased;
    },
    addData() {
      if (this.hasPurchased()) {
        if (this.key && this.value) {
          if (this.editingIndex > -1) {
            // 编辑已有数据
            this.chartData[this.editingIndex] = { name: this.key, value: this.value };
            this.editingIndex = -1;
          } else {
            // 添加新数据
            this.chartData.push({ name: this.key, value: this.value });
          }
          this.key = '';
          this.value = '';
          this.updateChart();
        }else{
          this.Notify();
        }
      } else {
        this.priceVisible = true;
      }
    },
    generateData() {
      // 初始化一批数据
      this.chartData = [
        { name: '❤️', value: 1500 },
        { name: '🎄', value: 1500 },
        { name: '🎃', value: 1500 },
        { name: 'WordCloud', value: 500 },
        { name: 'سحابة الكلمات', value: 100 },
        { name: 'Облак на думи', value: 100 },
        { name: 'Núvol de paraules', value: 100 },
        { name: 'Oblak riječi', value: 100 },
        { name: 'Slovní mrak', value: 100 },
        { name: 'Ordmoln', value: 100 },
        { name: 'Woordenwolk', value: 100 },
        { name: 'Sõnapilv', value: 100 },
        { name: 'Sanapilvi', value: 100 },
        { name: 'Nuage de mots', value: 100 },
        { name: 'Wortwolke', value: 100 },
        { name: 'σύννεφο λέξεων', value: 100 },
        { name: 'ענן מילים', value: 100 },
        { name: 'शब्दांश', value: 100 },
        { name: 'Szófelhő', value: 100 },
        { name: 'Awan Kata', value: 100 },
        { name: 'Parole di nuvola', value: 100 },
        { name: 'ワードクラウド', value: 100 },
        { name: '단어 구름', value: 100 },
        { name: 'Vārdu mākoņa', value: 100 },
        { name: 'Žodžių debesys', value: 100 },
        { name: 'Awan Kata', value: 200 },
        { name: 'Ordsky', value: 200 },
        { name: 'Słowo chmura', value: 200 },
        { name: 'Nuvem de palavras', value: 200 },
        { name: 'Nor de cuvinte', value: 200 },
        { name: 'Облако слов', value: 200 },
        { name: '词云词', value: 200 },
        { name: 'Slovný mrak', value: 300 },
        { name: 'Oblak besed', value: 300 },
        { name: 'Nube de palabras', value: 300 },
        { name: 'Ordmoln', value: 330 },
        { name: 'Ord Moln', value: 340 },
        { name: 'Mây từ', value: 350 },
        { name: 'Bulut kelime', value: 360 },
        { name: 'Хмара слів', value: 370 },
        { name: 'คำศัพท์เมฆ', value: 550 },
        { name: 'Felhő szavak', value: 560 },
        { name: 'Oblak reči', value: 570 },
        { name: 'Wörterwolke', value: 580 },
        { name: 'Chmura słów', value: 590 },
        { name: 'Wolken Wörter', value: 600 },
        { name: 'Lluvia de palabras', value: 610 },
        { name: 'Oblak slov', value: 620 },
        { name: 'Kala Megha', value: 630 },
        { name: 'Pulut kata', value: 640 },
        { name: 'Nimbus de verbis', value: 650 },
        { name: 'Fjalë re', value: 660 },
        { name: 'Ule mujin', value: 670 },
        { name: 'Finden Worte', value: 680 },
        { name: 'Словесный туман', value: 690 },
        { name: 'Orage de mots', value: 700 },
        { name: 'Pluie de mots', value: 710 },
        { name: 'Oblako slov', value: 720 },
        { name: 'Wolk met woorden', value: 730 },
        { name: 'Palavras da nuvem', value: 740 },
        { name: 'Bara de palabras', value: 750 },
        { name: 'Χείμαρρος λέξεων', value: 760 },
        { name: 'Słowna burza', value: 770 },
        { name: 'Морето на думите', value: 780 },
        { name: 'Sanajetuk', value: 790 },
        { name: 'Kelime dalgaları', value: 800 },
        { name: 'Orage de mots', value: 810 },
        { name: 'Mots flottants', value: 820 },
        { name: 'Kumo no kotoba', value: 830 },
        { name: 'Cortina de palabras', value: 840 },
        { name: 'Palavras dispersas', value: 850 },
        { name: 'Foglie di parole', value: 860 },
        { name: 'Pioggia di lettere', value: 870 },
        { name: 'Verbos en el aire', value: 880 },
        { name: 'Palabras en vuelo', value: 890 },
        { name: 'Orage de phrases', value: 900 },
        { name: 'Pluie de lettres', value: 910 },
        { name: 'Cielo de verbos', value: 920 },
        { name: 'Verbo nuvens', value: 930 },
        { name: 'Marea de palabras', value: 940 },
        { name: 'Bruma de palabras', value: 950 },
        { name: 'Palabras voladoras', value: 960 },
        { name: 'Vocabcloud', value: 970 },
        { name: 'Globo de palabras', value: 980 },
        { name: 'Wolkwoorden', value: 990 },
        { name: 'Kelime bulutu', value: 1000 }
      ];
      const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
      const generateRandomData = (index) => ({
          name: `${index}`,
          value: getRandomInt(50, 100)
      });

      while (this.chartData.length < 277) {
          this.chartData.push(generateRandomData(this.chartData.length + 1));
      }
      this.updateChart();
    },
    changeShape(shape) {
      // 修改词云图的形状
      this.chart.setOption({
        series: [{
          type: 'wordCloud',
          shape: shape
        }]
      });
      this.updateChart();
    },
    async updateChart() {
      if (!this.$refs.chart) {
        return;
      }

      if (!this.hasPurchased()) {
        this.imageHeight = 512;
        this.imageWidth = 512;
        this.priceVisible = true;
        return;
      }

      try {
        // 确保字体加载完成
        await this.loadSelectedFont(this.selectedFont);

        const myChart = echarts.init(this.$refs.chart);
        const option = {
          series: [{
            type: 'wordCloud',
            sizeRange: [this.minSize, this.maxSize],
            rotationRange: [-90, 90],
            rotationStep: 1,
            gridSize: 1,
            drawOutOfBound: false,
            shrinkToFit: true,
            keepAspect: false,
            layoutAnimation: true,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            right: 0,
            bottom: 0,
            textStyle: {
              fontFamily: this.selectedFont,
              fontWeight: 'bold',
              color: function () {
                return 'rgb(' + [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160)
                ].join(',') + ')';
              }
            },
            data: this.chartData
          }]
        };

        // 处理遮罩图片
        if (this.selectedMask) {
          const maskImage = new Image();
          maskImage.onload = () => {
            option.series[0].maskImage = maskImage;
            myChart.setOption(option);
            myChart.resize();
          };
          maskImage.src = this.selectedMask;
        } else {
          const maskImage = new Image();
          maskImage.onload = () => {
            option.series[0].maskImage = maskImage;
            myChart.setOption(option);
            myChart.resize();
          };
          maskImage.src = dogImg;
        }

      } catch (error) {
        console.error('Error updating chart:', error);
        // 发生错误时显示通知
        this.Notify('error', this.$t('Error'), error.message);
      }
    },
    editData(index) {
      // 编辑数据
      this.editingIndex = index;
      this.key = this.chartData[index].name;
      this.value = this.chartData[index].value;
    },
    deleteData(index) {
      // 删除数据
      this.chartData.splice(index, 1);
      this.updateChart();
    },
    handleMaskSelected(mask) {
      // 接收口罩选择事件，并更新选定的口罩数据
      this.selectedMask = mask;
      this.updateChart();
      this.maskVisible = false;
    },
    exportLayout() {
      const chartElement = this.$refs.chart;
      // 使用HTML2Canvas将DOM元素转换为图片
      html2canvas(chartElement, {
        backgroundColor: this.backgroundColor, // 设置背景颜色
        logging: false // 可选，禁用HTML2Canvas日志输出
      }).then(canvas => {
        // 将Canvas转换为图片URL
        const dataURL = canvas.toDataURL('image/png');
        // 创建一个下载链接并设置图片URL
        const a = document.createElement('a');
        a.href = dataURL;
        const date = new Date();
        const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        a.download = `wordcloud_${dateString}.png`;
        // 模拟点击下载链接
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }
};
</script>