<template>
  <div class="text-center">
    <h1 class="text-4xl font-bold mb-4">{{ $t('ChoosePlan') }}</h1>
    <h3 class="text-xl text-gray-600 mb-8">{{ $t("PayHint") }}</h3>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

      <div class="plan-card bg-blue-100 shadow-lg rounded-lg p-6">
        <img :src="VIP" alt="VIP Image" class="mx-auto mb-4 w-16 h-16">
        <h3 class="text-2xl font-semibold mb-2 text-blue-800">{{ $t('MonthlyVIP') }}</h3>
        <p class="text-lg text-blue-700 mb-2">$2.99{{ $t('MonthlyVIPTime')}}</p>
        <p class="text-sm text-blue-600 mb-4">{{ $t('MonthlyVIPHint') }}</p>
        <el-button type="primary" @click="openCheckout('pri_01htq3vq91c18n974hrf84c17q')">{{ $t('Subscribe') }}</el-button>
      </div>

      <div class="plan-card bg-green-100 shadow-lg rounded-lg p-6">
        <img :src="VIP" alt="VIP Image" class="mx-auto mb-4 w-16 h-16">
        <h3 class="text-2xl font-semibold mb-2 text-green-800">{{ $t('QuartelyVIP') }}</h3>
        <p class="text-lg text-green-700 mb-2">$8.88{{ $t('QuartelyVIPTime')}}</p>
        <p class="text-sm text-green-600 mb-4">{{ $t('QuartelyVIPHint') }}</p>
        <el-button type="success" @click="openCheckout('pri_01hv37kcen5k323v7jzq03a3hh')">{{ $t('Subscribe') }}</el-button>
      </div>

      <div class="plan-card bg-red-100 shadow-lg rounded-lg p-6">
        <img :src="VIP" alt="VIP Image" class="mx-auto mb-4 w-16 h-16">
        <h3 class="text-2xl font-semibold mb-2 text-red-800">{{ $t('AnnualVIP') }}</h3>
        <p class="text-lg text-red-700 mb-2">$28.88{{ $t('AnnualVIPTime')}}</p>
        <p class="text-sm text-red-600 mb-4">{{ $t('AnnualVIPHint') }}</p>
        <el-button type="danger" @click="openCheckout('pri_01hv37ntpgqgf0nf4vhkzy6st5')">{{ $t('Subscribe') }}</el-button>
      </div>   
      
      <div class="plan-card bg-yellow-100 shadow-lg rounded-lg p-6">
        <img :src="VIP" alt="VIP Image" class="mx-auto mb-4 w-16 h-16">
        <h3 class="text-2xl font-semibold mb-2 text-yellow-800">{{ $t('DailyPlan') }}</h3>
        <p class="text-lg text-yellow-700 mb-2">$0.7{{ $t('DailyPlanTime')}}</p>
        <p class="text-sm text-yellow-600 mb-4">{{ $t('DailyPlanHint') }}</p>
        <el-button type="primary" @click="openCheckout('pri_01htq3wm34engpcy5905fc3hwp')">{{ $t('Pay') }}</el-button>
      </div>               

    </div>
    <p class="mt-8 text-gray-500">
      Copyright © emperinter
    </p>
  </div>
</template>

<script>
import VIP from '@/assets/vip.png';

export default {
  name: 'PricePage',
  data() {
    return {
      isSubscribed: false,
      subscriptionExpiration: null,
      VIP: VIP
    };
  },
  mounted() {
    const purchaseStatus = localStorage.getItem("purchaseStatus");
    const expirationTime = localStorage.getItem("expirationTime");
    if (purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime)) {
      this.isSubscribed = true;
      this.subscriptionExpiration = new Date(expirationTime).toLocaleString();
    }else{
      this.isSubscribed = false;
      this.subscriptionExpiration = new Date().toLocaleString();
      localStorage.clear;
    }
  },
  methods: {
    openCheckout(priceId) {
      // eslint-disable-next-line no-undef
      // Paddle.Environment.set("sandbox");

      // eslint-disable-next-line no-undef
      Paddle.Initialize({
        // token: "test_f4c61ffd10ba901196a31fa938b",
        token: "live_08ccd4dc3ab91634d0586e4b147",
        eventCallback: function (data) {
          if (data.name == "checkout.completed") {
            if (data.data.items[0].price_name == "DailyPlan") {
              const expirationTime = new Date();
              // One Day
              expirationTime.setDate(expirationTime.getDate() + 1); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "DailyPlan");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "MonthlyPlan"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 31); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "MonthlyPlan");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "MonthlyVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 31); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "MonthlyVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "QuartelyVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 93); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "QuartelyVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }else if(data.data.items[0].price_name == "AnnualVIP"){
              const expirationTime = new Date();
              expirationTime.setDate(expirationTime.getDate() + 365); // Set expiration time to one day from now
              localStorage.setItem("purchaseStatus", "purchased");
              localStorage.setItem("price_name", "AnnualVIP");
              localStorage.setItem("expirationTime", expirationTime.toISOString());
            }
          }
        }
      });

      let itemsList = [{ priceId: priceId, quantity: 1 }];

      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        items: itemsList
      });
    }
  }
};
</script>

<style scoped>
.plan-card-daily{
  text-align: center;
  background-color: #bdbfc5;
  color: rgb(0, 0, 0);
}

.plan-card-monthly{
  text-align: center;
  background-color: #604800;
  color: aquamarine;
}

.plan-card-quartely{
  text-align: center;
  background-color: #494826;
  color:#f0f5ff;
}

.plan-card-annual{
  text-align: center;
  background-color: #000000;
  color: #fff700;
}

img {
  /* 设置图像宽度 */
  width: 38px;
  /* 设置图像高度 */
  height: 38px;
  /* 设置图像居中 */
  margin: 0 auto;
  /* 设置图像垂直居中 */
  display: block;
}
</style>
